<template>
  <div class="content">
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-12 col-sm">
          <PageHeader :breadcrumbLinks="breadcrumbLinks" title="Logs" />
            <!-- Search column -->
            <div class="col-8 mx-auto mb-5">
              <div class="form-wrapper">
                <form @submit.prevent="fetch" class="form-group col px-0 mb-0">
                  <div class="input-group">
                    <input
                      placeholder=""
                      type="text"
                      class="form-control"
                      :v-model="searchQuery"
                      ref="searchInput"
                    />
                    <div class="input-group-prepend">
                      <button @click="fetch(1)" type="button" class="btn icon_btn m-0">
                        <span class="material-symbols-outlined"> search </span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <!--/Search column -->
            <!-- Log content -->
            <LogList />
            <!--/Log content -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/rebranding/PageHeader.vue';
import LogList from '@/components/rebranding/logs-report/LogList.vue';
import Swal from 'sweetalert2';

export default {
  name: 'AdminLogs',
  components: {
    PageHeader,
    LogList,
    // Pagination,
    // HiddenInput,
    // Darkmode,
    // LoadingAnim,
  },
  data() {
    return {
      breadcrumbLinks: {
        1: '/pipelines',
      },
      fetched: false,
      searchQuery: null,
      isSending: false,
      pages: 1,
      logs: [],
      dt1: '2023-12-05T12:13:29.000000Z',
      dt2: '2023-12-06T12:56:13.000000Z',
      dt3: '2003-12-06T12:56:13.000000Z',
    };
  },
};
</script>
<style lang="scss" scoped>
/* Search form style */
.form-group {
  label {
    color: var(--foreground);
    font-size: 16px;
    margin-bottom: 20px;
  }
  input:-ms-input-placeholder {
    text-transform: lowercase !important;
  }
  input::-webkit-input-placeholder {
    text-transform: lowercase !important;
  }
  input::placeholder {
    text-transform: lowercase !important;
  }

  #inputPassword {
    border-right-color: transparent;
    border-right-width: 0;
  }

  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: transparent;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}
.darkmode .rebranding .form-group .form-control,
.rebranding .form-group .form-control {
  border-right: unset !important;
}
.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}
.form-wrapper {
  flex: 1 0 20%;
  .form-group {
    height: 55px;
  }
  .form-control,
  .input-group-prepend,
  .input-group,
  .icon_btn {
    height: 100%;
    span {
      color: var(--gray-font-color-5);
      font-size: 38px;
    }
  }
}
@media (min-width: 1660px){
  .form-wrapper {
    min-width: 0;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex-basis: 0;
    flex-grow: 1;
  }
}
@media (max-width: 767.98px) {
  .form-wrapper {
    flex: 1 0 40%;
  }
}
/*/Search Form style */
</style>
